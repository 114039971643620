import { url as urlTools } from 'tools';

export default function loadSVG(
	input: string | File,
): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const img = document.createElement('img');
		img.crossOrigin = 'anonymous';
		img.onload = () => {
			resolve(img);

			if (typeof input !== 'string') {
				URL.revokeObjectURL(img.src);
			}
		};
		img.onerror = () => {
			reject(new Error('Could not load the SVG image'));
		};

		if (typeof input === 'string') {
			img.src = urlTools.appendParameter(
				input,
				'noCorsHeader',
			);
		} else {
			img.src = URL.createObjectURL(input);
		}
	});
}
