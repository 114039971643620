import * as PI from 'interfaces/project';
import defaults from '../defaults';

export const nonBreakingSpace = '\u00A0'; // Same as &nbsp;

export interface SetSVGSelectionOptions {
	renderCaret: boolean;
	renderSelection: boolean;
	scaling: number;
}

/**
 * Current creator's version of typescript (4.5.5) does not have the `x` and `y` properties
 * in the `CSSStyleDeclaration` interface, so we manually add them here for now.
 */
interface CSSStyleDeclarationWithXAndY extends CSSStyleDeclaration {
	x: string;
	y: string;
}

export function clearSelection(svgElement: SVGElement): void {
	const existingSelectionElements = svgElement.querySelectorAll('.svg-selection');
	const existingCaretElement = svgElement.querySelector('.svg-caret');

	if (existingSelectionElements.length) {
		existingSelectionElements.forEach((selectionRect) => selectionRect.remove());
	}

	existingCaretElement?.remove();
}

function getTextStyles(
	objectModel: OptionalExceptFor<PI.PageObjectModel, 'height' | 'width'>,
	textLineIndex: number,
): Partial<CSSStyleDeclarationWithXAndY> {
	const pointsize = objectModel.pointsize || defaults.pointsize;
	const fontSizePx = (pointsize * 31496) / 15120;
	const lineHeight = fontSizePx * 1.25;
	const y = ((0.5 + textLineIndex) * lineHeight) + (objectModel.cropy || 0);

	const styles: Partial<CSSStyleDeclarationWithXAndY> = {
		dominantBaseline: 'middle',
		y: `${Math.round(y)}`,
	};

	if (objectModel.align === 'Center') {
		styles.x = `${Math.round((objectModel.width) / 2)}`;
		styles.textAnchor = 'middle';
	} else if (objectModel.align === 'Right') {
		styles.x = `${Math.round(objectModel.width)}`;
		styles.textAnchor = 'end';
	} else if (objectModel.align === 'Left') {
		styles.x = '0';
		styles.textAnchor = 'start';
	}

	// We need to add the font weight and style to the fontface name
	let font = objectModel.fontface || 'unset';
	if (objectModel.fontface
		&& objectModel.fontitalic
		&& objectModel.fontbold
	) {
		font += ' BoldItalic';
	} else if (objectModel.fontface
		&& objectModel.fontitalic
	) {
		font += ' Italic';
	} else if (objectModel.fontface
		&& objectModel.fontbold
	) {
		font += ' Bold';
	}

	styles.fontSize = `${Math.round(fontSizePx)}px`;
	styles.fontFamily = font;
	styles.textTransform = 'none';

	return styles;
}

export function createText(objectModel: OptionalExceptFor<PI.PageObjectModel, 'height' | 'width'>): string {
	let svgText = '';
	const textObjectWidth = Math.round(objectModel.width);
	const textObjectHeight = Math.round(objectModel.height);
	const text = objectModel.text_formatted || '';
	const svgElement = document.createElementNS(
		'http://www.w3.org/2000/svg',
		'svg',
	);
	svgElement.setAttributeNS(
		null,
		'version',
		'1.1',
	);
	svgElement.setAttributeNS(
		null,
		'encoding',
		'UTF-8',
	);
	svgElement.setAttributeNS(
		'http://www.w3.org/XML/1998/namespace',
		'xml:space',
		'preserve',
	);
	svgElement.setAttributeNS(
		null,
		'viewBox',
		`0 0 ${textObjectWidth} ${textObjectHeight}`,
	);
	svgElement.setAttributeNS(
		null,
		'width',
		textObjectWidth.toString(),
	);
	svgElement.setAttributeNS(
		null,
		'height',
		textObjectHeight.toString(),
	);
	svgElement.setAttributeNS(
		null,
		'style',
		'left: 0; position: absolute; top: 0; visibility: hidden; pointer-events: none;',
	);
	document.body.appendChild(svgElement);

	try {
		if (objectModel.bgcolor) {
			const svgBackgroundRectElement = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'rect',
			);
			svgBackgroundRectElement.setAttributeNS(
				null,
				'class',
				'svg-background',
			);
			svgBackgroundRectElement.setAttributeNS(
				null,
				'x',
				'0',
			);
			svgBackgroundRectElement.setAttributeNS(
				null,
				'y',
				'0',
			);
			svgBackgroundRectElement.setAttributeNS(
				null,
				'width',
				textObjectWidth.toString(),
			);
			svgBackgroundRectElement.setAttributeNS(
				null,
				'height',
				Math.round(textObjectHeight).toString(),
			);
			svgBackgroundRectElement.setAttributeNS(
				null,
				'fill',
				objectModel.bgcolor,
			);
			svgElement.appendChild(svgBackgroundRectElement);
		}

		const textLines = text.split('\n');
		const textLinesWithHyphens = (objectModel.text_formatted_for_canvas || objectModel.text_formatted || '').split('\n');

		for (let textLineIndex = 0; textLineIndex < textLines.length; textLineIndex += 1) {
			let textLine = textLines[textLineIndex];
			const textStyles = getTextStyles(
				objectModel,
				textLineIndex,
			);
			const textElement = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'text',
			);
			textElement.setAttributeNS(
				null,
				'fill',
				objectModel.fontcolor || '#000000',
			);

			if (textStyles.x) {
				textElement.setAttributeNS(
					null,
					'x',
					Math.round(parseFloat(textStyles.x)).toString(),
				);
			}
			if (textStyles.y) {
				textElement.setAttributeNS(
					null,
					'y',
					Math.round(parseFloat(textStyles.y)).toString(),
				);
			}

			// eslint-disable-next-line no-restricted-syntax
			for (const [styleKey, styleValue] of Object.entries(textStyles)) {
				if (
					styleKey !== 'x'
					&& styleKey !== 'y'
					&& styleValue !== 'unset'
				) {
					(textElement.style as any)[styleKey] = styleValue;
				}
			}

			if (textLine === '') {
				textLine = nonBreakingSpace;
			} else {
				textLine = textLine.replace(
					/\s$/,
					nonBreakingSpace,
				);
			}

			const textNode = document.createTextNode(textLine);
			textElement.appendChild(textNode);
			svgElement.appendChild(textElement);
			const textElementBBox = textElement.getBBox();
			textNode.textContent = textLinesWithHyphens[textLineIndex];

			if (textElement.style.textAnchor) {
				textElement.style.textAnchor = 'start';
				textElement.setAttributeNS(
					null,
					'x',
					Math.round(textElementBBox.x).toString(),
				);
			}
		}

		document.body.removeChild(svgElement);
		svgElement.removeAttributeNS(
			null,
			'style',
		);
		svgText = new XMLSerializer().serializeToString(svgElement);
	} finally {
		svgElement.remove();
	}

	return svgText;
}

export function normalizeTextForSelection(text: string): string {
	return text
		.split('\n')
		.map((line) => (
			line
				.replace(
					/\s{2}/g, // Two spaces in a row
					` ${nonBreakingSpace}`,
				)
				.replace(
					/\s$/g, // Space at the end of the line
					nonBreakingSpace,
				)
				.replace(
					/^\s/g, // Space at the beginning of the line
					nonBreakingSpace,
				)
				.replace(
					/^$/g, // Empty line
					nonBreakingSpace,
				)
		))
		.join('\n');
}

export function setSelection(
	objectModel: PI.PageObjectModel,
	svgElement: SVGElement,
	selection: {
		caretLine: number;
		start: number;
		end: number;
	},
	options?: PublicOptionalProps<SetSVGSelectionOptions>,
): void {
	const defaultOptions: SetSVGSelectionOptions = {
		renderCaret: false,
		renderSelection: true,
		scaling: 1,
	};
	const finalOptions: SetSVGSelectionOptions = {
		...defaultOptions,
		...(options || {}),
	};
	finalOptions.scaling = finalOptions.scaling || 1;

	clearSelection(svgElement);
	const textChildren = Array
		.from(svgElement.childNodes as NodeListOf<SVGTextElement>)
		.filter((child) => child.tagName === 'text');
	const text = objectModel.text || '';
	const textLines = text.split('\n');
	const textFormattedForCanvas = objectModel.text_formatted_for_canvas || '';
	const textFormattedForCanvasLines = textFormattedForCanvas.split('\n');

	if (
		selection.start === selection.end
		&& finalOptions.renderCaret
	) {
		let styleElement: SVGStyleElement | undefined;

		if (!svgElement.querySelector('style')) {
			styleElement = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'style',
			);
			styleElement.textContent = `
				@keyframes blink-caret {
					50% {
						opacity: 0;
					}
				}
			`;
		}

		for (let index = 0; index < textChildren.length; index += 1) {
			const textLine = textLines[index];
			const textFormattedForCanvasLine = textFormattedForCanvasLines[index];
			const textChild = textChildren[index];
			const textLength = textChild.textContent?.length || 0;

			if (
				selection.start <= textLength
				&& selection.caretLine === (index + 1)
			) {
				const svgCaretRectElement = document.createElementNS(
					'http://www.w3.org/2000/svg',
					'rect',
				);
				svgCaretRectElement.setAttributeNS(
					null,
					'class',
					'svg-caret',
				);
				svgCaretRectElement.setAttributeNS(
					null,
					'style',
					'animation: blink-caret 1s step-end infinite; dominant-baseline: middle; fill: var(--svg-caret-color, #000);',
				);
				let startOffset: SVGPoint;
				const textChildBBox = textChild.getBBox();

				if (selection.start < textLength) {
					startOffset = textChild.getStartPositionOfChar(selection.start);
				} else if (textLength > 0) {
					startOffset = textChild.getEndPositionOfChar(textLength - 1);
				} else {
					startOffset = textChild.getStartPositionOfChar(0);
				}

				svgCaretRectElement.setAttributeNS(
					null,
					'x',
					`${Math.round(startOffset.x)}`,
				);
				svgCaretRectElement.setAttributeNS(
					null,
					'y',
					`${Math.round(textChildBBox.y)}`,
				);
				svgCaretRectElement.setAttributeNS(
					null,
					'width',
					Math.round(1 / finalOptions.scaling).toString(),
				);
				svgCaretRectElement.setAttributeNS(
					null,
					'height',
					`${Math.round(textChildBBox.height)}`,
				);

				const svgBackgroundRectElement = svgElement.querySelector('.svg-background');

				if (svgBackgroundRectElement) {
					svgBackgroundRectElement.after(svgCaretRectElement);
				} else {
					svgElement.prepend(svgCaretRectElement);
				}
				break;
			} else {
				if (
					(
						textFormattedForCanvasLine.at(-1) === nonBreakingSpace
						&& textLine.at(-1) === ' '
					)
					|| (
						textFormattedForCanvasLine.at(-1) !== '-'
						&& textLine !== ''
					)
					|| (
						(textChild.textContent || '').at(-1) === '-'
						&& textFormattedForCanvasLine.at(-1) === '-'
					)
				) {
					selection.start -= textLength + 1;
				} else {
					selection.start -= textLength;
				}

				if (selection.start < 0) {
					selection.start = 0;
				}
			}

			if (
				(
					textFormattedForCanvasLine.at(-1) === nonBreakingSpace
					&& textLine.at(-1) === ' '
				)
				|| (
					textFormattedForCanvasLine.at(-1) !== '-'
					&& textLine !== ''
				)
				|| (
					(textChild.textContent || '').at(-1) === '-'
					&& textFormattedForCanvasLine.at(-1) === '-'
				)
			) {
				selection.end -= textLength + 1;
			} else {
				selection.end -= textLength;
			}

			if (selection.end < 0) {
				break;
			}
		}

		if (typeof styleElement !== 'undefined') {
			svgElement.prepend(styleElement);
		}
	} else if (finalOptions.renderSelection) {
		for (let index = 0; index < textChildren.length; index += 1) {
			const textFormattedLine = textFormattedForCanvasLines[index];
			const textChild = textChildren[index];
			const svgSelectionRectElement = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'rect',
			);
			svgSelectionRectElement.setAttributeNS(
				null,
				'class',
				'svg-selection',
			);
			svgSelectionRectElement.setAttributeNS(
				null,
				'style',
				'dominant-baseline: middle; fill: var(--svg-selection-color, #b7d7fe);',
			);
			const textLength = textChild.textContent?.length || 0;

			if (selection.start < textLength) {
				const startOffset = textChild.getStartPositionOfChar(selection.start);
				let endOffset: SVGPoint;
				const textChildBBox = textChild.getBBox();

				if (selection.end >= textLength) {
					endOffset = textChild.getEndPositionOfChar(textLength - 1);
				} else {
					endOffset = textChild.getStartPositionOfChar(selection.end);
				}

				svgSelectionRectElement.setAttributeNS(
					null,
					'x',
					`${Math.round(startOffset.x)}`,
				);
				svgSelectionRectElement.setAttributeNS(
					null,
					'y',
					`${Math.round(textChildBBox.y)}`,
				);
				svgSelectionRectElement.setAttributeNS(
					null,
					'width',
					`${Math.round(endOffset.x - startOffset.x)}`,
				);
				svgSelectionRectElement.setAttributeNS(
					null,
					'height',
					`${Math.round(textChildBBox.height)}`,
				);

				const svgBackgroundRectElement = svgElement.querySelector('.svg-background');

				if (svgBackgroundRectElement) {
					svgBackgroundRectElement.after(svgSelectionRectElement);
				} else {
					svgElement.prepend(svgSelectionRectElement);
				}

				if (selection.end <= textLength) {
					break;
				}

				selection.start = 0;
			} else {
				if (
					textFormattedLine.at(-1) === nonBreakingSpace
					|| textFormattedLine.at(-1) !== '-'
					|| (
						(textChild.textContent || '').at(-1) === '-'
						&& textFormattedLine.at(-1) === '-'
					)
				) {
					selection.start -= textLength + 1;
				} else {
					selection.start -= textLength;
				}

				if (selection.start < 0) {
					selection.start = 0;
				}
			}

			if (
				textFormattedLine.at(-1) === nonBreakingSpace
				|| textFormattedLine.at(-1) !== '-'
				|| (
					(textChild.textContent || '').at(-1) === '-'
					&& textFormattedLine.at(-1) === '-'
				)
			) {
				selection.end -= textLength + 1;
			} else {
				selection.end -= textLength;
			}

			if (selection.end < 0) {
				break;
			}
		}
	}
}

export function stripHtmlEntities(text: string): string {
	return text
		.replace(
			/&nbsp;/g,
			' ',
		)
		.replace(
			/&amp;/g,
			'&',
		)
		.replace(
			/&lt;/g,
			'<',
		)
		.replace(
			/&gt;/g,
			'>',
		)
		.replace(
			/&quot;/g,
			'"',
		)
		.replace(
			/&apos;/g,
			"'",
		);
}
